import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { LOGIN_ROUTE, REFRESH_TOKEN_NAME } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  getIsAuth(): string {
    return localStorage.getItem('id_token');
  }

  logOut(): void {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem(REFRESH_TOKEN_NAME);

    Sentry.setUser(null);

    // reinitializing the application state from scratch
    window.location.href = LOGIN_ROUTE;
  }
}
