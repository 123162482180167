import { Injectable } from '@angular/core';
import { Router, CanActivate, MaybeAsync, GuardResult } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): MaybeAsync<GuardResult> {
    const isAuth = this.authService.getIsAuth();

    if (!isAuth) {
      return this.router.parseUrl('/auth');
    }

    return true;
  }
}
